
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minValue, helpers } from "@vuelidate/validators";
import Layout from "./_Layout.vue";
import Field from "./_Field.vue";
import Buttons from "./_Buttons.vue";

export default defineComponent({
  name: "Investment",
  components: { Layout, Field, Buttons },
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      submitted: false,
    };
  },
  computed: {
    investmentType: {
      get() {
        return this.$store.state.planner.investmentType;
      },
      set(value: string) {
        this.$store.commit("planner/setValues", {
          name: "investmentType",
          value: value,
        });
      },
    },
    investmentTotal: {
      get() {
        return this.$store.state.planner.investmentTotal;
      },
      set(value: string) {
        this.$store.commit("planner/setValues", {
          name: "investmentTotal",
          value: +value,
        });
      },
    },
    investmentName: {
      get() {
        return this.$store.state.planner.investmentName;
      },
      set(value: string) {
        this.$store.commit("planner/setValues", {
          name: "investmentName",
          value: value,
        });
      },
    },
  },
  validations() {
    const requiredMessage = "Bitte geben Sie einen Wert ein";
    const rules = {
      investmentType: {
        required,
      },
      investmentTotal: {
        required: helpers.withMessage(requiredMessage, required),
        minValue: helpers.withMessage(requiredMessage, minValue(1)),
      },
    };

    return rules;
  },
  methods: {
    async validate() {
      const isValid = await this.v$.$validate();

      if (!isValid) {
        this.scrollToFirstInvalidElement(0);
      }

      this.submitted = true;

      return isValid;
    },
    scrollToFirstInvalidElement(retry: number) {
      retry++;

      const element = document.querySelector(".p-invalid");
      if (element) {
        const headerOffset = 37;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      } else if (retry < 10) {
        setTimeout(this.scrollToFirstInvalidElement, 100);
      }
    },
  },
});
