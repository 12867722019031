import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cbe3974e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-w-4xl px-2 pt-8 mx-auto lg:pt-15" }
const _hoisted_2 = { class: "flex flex-col p-4 text-black lg:p-8 a-container rounded-2xl" }
const _hoisted_3 = { class: "p-1 headline" }
const _hoisted_4 = { class: "text-2xl lg:text-5xl lg:mb-4 lg:leading-15" }
const _hoisted_5 = { class: "w-full stepper-container" }
const _hoisted_6 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Stepper = _resolveComponent("Stepper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "header", {}, undefined, true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, [
          _renderSlot(_ctx.$slots, "title", {}, undefined, true)
        ]),
        _createElementVNode("p", null, [
          _renderSlot(_ctx.$slots, "description", {}, undefined, true)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Stepper)
      ]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _renderSlot(_ctx.$slots, "buttons", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ])
  ]))
}