export const currencyFormatter = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const dateFormatter = new Intl.DateTimeFormat("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" });

export const dateFromISOString = (date?: string): string => {
  if (!date) {
    date = new Date().toISOString();
  }
  return new Date(date).toISOString().slice(0, 10);
};
