import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "primevue/resources/themes/fluent-light/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "./assets/tailwind.css";

import PrimeVue from "primevue/config";

import Steps from "primevue/steps";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import Checkbox from "primevue/checkbox";
import RadioButton from "primevue/radiobutton";
import Slider from "primevue/slider";
import ToastService from "primevue/toastservice";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Avatar from "primevue/avatar";
import Tag from "primevue/tag";
import ProgressBar from "primevue/progressbar";
import Tooltip from "primevue/tooltip";

import "@/assets/scss/main.scss";
import "./styles.scss";

createApp(App)
  .use(store)
  .use(router)
  .use(PrimeVue)
  .use(ToastService)
  .component("Steps", Steps)
  .component("Dropdown", Dropdown)
  .component("Checkbox", Checkbox)
  .component("RadioButton", RadioButton)
  .component("InputText", InputText)
  .component("InputNumber", InputNumber)
  .component("Textarea", Textarea)
  .component("Button", Button)
  .component("Slider", Slider)
  .component("Accordion", Accordion)
  .component("AccordionTab", AccordionTab)
  .component("Avatar", Avatar)
  .component("Tag", Tag)
  .component("ProgressBar", ProgressBar)
  .directive("tooltip", Tooltip)
  .mount("#app");
