import config from "@/configuration.json";
import router from "@/router/index";

export interface StepItem {
  id: string;
  title: string;
  route: string;
}

export interface StepsState {
  steps: Array<StepItem>;
  completedSteps: Array<string>;
}

export interface StepsGetters {
  availableSteps: Array<StepItem>;
}

export type IsStepCompletedFunc = (step: StepItem) => boolean;

export default {
  namespaced: true,
  state: {
    steps: config.steps,
    completedSteps: [],
  },
  getters: {
    availableSteps(state: StepsState): Array<StepItem> {
      return state.steps;
    },
    prevStep(state: StepsState, getters: StepsGetters): StepItem | null {
      const availableSteps = getters.availableSteps;
      const stepIndex = availableSteps.findIndex((x) => x.id == router.currentRoute.value.name);
      if (stepIndex > 0) {
        return availableSteps[stepIndex - 1];
      }
      return null;
    },
    currentStep(state: StepsState, getters: StepsGetters): StepItem | undefined {
      const availableSteps = getters.availableSteps;
      return availableSteps.find((x) => x.id == router.currentRoute.value.name);
    },
    nextStep(state: StepsState, getters: StepsGetters): StepItem | null {
      const availableSteps = getters.availableSteps;
      const stepIndex = availableSteps.findIndex((x) => x.id == router.currentRoute.value.name);
      if (stepIndex < availableSteps.length - 1) {
        return availableSteps[stepIndex + 1];
      }
      return null;
    },
    isStepCompleted:
      (state: StepsState): IsStepCompletedFunc =>
      (step: StepItem) => {
        return state.completedSteps.includes(step.id);
      },
  },
  mutations: {
    stepCompleted(state: StepsState, payload: string): void {
      const step = state.completedSteps.find((x) => x == payload);
      if (!step) {
        state.completedSteps.push(payload);
      }
    },
  },
  actions: {},
};
