
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Toast from "primevue/toast";

export default defineComponent({
  name: "App",
  components: {
    Header,
    Footer,
    Toast,
  },
});
