
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { StepItem } from "@/store/modules/steps";

export default defineComponent({
  name: "Stepper",
  components: {},
  computed: {
    ...mapGetters("steps", ["availableSteps", "isStepCompleted"]),
  },
  methods: {
    goto(path: string, event: MouseEvent) {
      this.$router.push({ path: path });
      event.preventDefault();
    },
    stepCompleted(item: StepItem) {
      const step = (this.availableSteps as Array<StepItem>).find((x) => x.id == item.id);
      if (step) {
        return this.isStepCompleted(step);
      }

      return false;
    },
    stepActive(item: StepItem) {
      let isActive = item.route == "/";
      const steps = this.availableSteps as Array<StepItem>;

      const stepIndex = steps.findIndex((x) => x.id == item.id);
      if (stepIndex) {
        isActive = isActive || this.isStepCompleted(this.availableSteps[stepIndex]);
      }

      if (stepIndex > 0 && this.isStepCompleted(this.availableSteps[stepIndex - 1])) {
        isActive = true;
      }

      if (!isActive) {
        isActive = this.stepHighlighted(item);
      }

      return isActive;
    },
    stepHighlighted(item: StepItem) {
      const cur = this.$router.currentRoute.value.name;
      return item.id == cur;
    },
    getHighlight(item: StepItem) {
      if (this.stepHighlighted(item)) {
        return "step-highlighted";
      }
      return "";
    },
    stepColor(item: StepItem) {
      if (this.stepCompleted(item)) {
        return "step-completed";
      }
      if (this.stepActive(item)) {
        return "step-active";
      }

      return "";
    },
  },
});
