
import { defineComponent } from "vue";
import Stepper from "@/components/Stepper.vue";

export default defineComponent({
  name: "Layout",
  components: {
    Stepper,
  },
});
