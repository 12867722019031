
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, email, sameAs, helpers } from "@vuelidate/validators";
import Layout from "./_Layout.vue";
import Field from "./_Field.vue";

import config from "@/configuration.json";
import { Product } from "@/store/modules/planner";
import { dateFromISOString } from "@/helper/formatter";
import uuidGenerator from "@/helper/uuidGenerator";

interface FormData {
  SubscriberKey: string;
  Email: string;
  FirstName?: string;
  LastName?: string;
  InvestInterval: string;
  InvestSumPerInterval: number;
  RegistrationDate: string;
  ETFPlanName: string;
  Prod1ISIN?: string;
  Prod1NAV?: string;
  Prod1NAVDate?: string;
  Prod1AmountAllocation?: number;
  Prod1PercentAllocation?: number;
  Prod2ISIN?: string;
  Prod2NAV?: string;
  Prod2NAVDate?: string;
  Prod2AmountAllocation?: number;
  Prod2PercentAllocation?: number;
  Prod3ISIN?: string;
  Prod3NAV?: string;
  Prod3NAVDate?: string;
  Prod3AmountAllocation?: number;
  Prod3PercentAllocation?: number;
  Prod4ISIN?: string;
  Prod4NAV?: string;
  Prod4NAVDate?: string;
  Prod4AmountAllocation?: number;
  Prod4PercentAllocation?: number;
  Prod5ISIN?: string;
  Prod5NAV?: string;
  Prod5NAVDate?: string;
  Prod5AmountAllocation?: number;
  Prod5PercentAllocation?: number;
  NoOfProductsSelected: number;
  EmailInterval: string;
  TotalAmountInvested: number;
  [propName: string]: string | number | undefined;
}

interface RequestData {
  ContactKey: string;
  EventDefinitionKey: string;
  Data: FormData;
}

export default defineComponent({
  name: "Registration",
  components: { Layout, Field },
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      dropdownOpen: false,
      submitted: false,
      intervalOptions: [
        { label: "monatlich", value: "monatlich" },
        { label: "wöchentlich", value: "wöchentlich" },
      ],
      sendButtonText: "Jetzt anmelden",
      sendButtonIcon: "",
      buttonsDisabled: false,
      termsUrl: config.termsUrl,
      privacyUrl: config.privacyUrl,
    };
  },
  computed: {
    ...mapGetters("steps", ["nextStep", "prevStep"]),
    ...mapGetters("planner", ["selectedProducts"]),

    name: {
      get() {
        return this.$store.state.planner.investorName;
      },
      set(value: string) {
        this.$store.commit("planner/setValues", {
          name: "investorName",
          value: value,
        });
      },
    },
    emailAddress: {
      get() {
        return this.$store.state.planner.investorEmail;
      },
      set(value: string) {
        this.$store.commit("planner/setValues", {
          name: "investorEmail",
          value: value,
        });
      },
    },
    interval: {
      get() {
        return this.$store.state.planner.interval;
      },
      set(value: string) {
        this.$store.commit("planner/setValues", {
          name: "interval",
          value: value,
        });
      },
    },
    termsAccepted: {
      get() {
        return this.$store.state.planner.termsAccepted;
      },
      set(value: boolean) {
        this.$store.commit("planner/setValues", {
          name: "termsAccepted",
          value: value,
        });
      },
    },
  },
  validations() {
    const requiredMessage = "Bitte geben Sie einen Wert ein";
    const emailMessage = "Bitte geben Sie eine E-Mail Adresse ein";
    const rules = {
      emailAddress: {
        required: helpers.withMessage(emailMessage, required),
        email: helpers.withMessage(emailMessage, email),
      },
      termsAccepted: {
        required: helpers.withMessage(requiredMessage, required),
        sameAsRawValue: sameAs(true),
      },
    };

    return rules;
  },
  methods: {
    async validate() {
      const isValid = await this.v$.$validate();

      if (!isValid) {
        this.scrollToFirstInvalidElement(0);
      }

      if (isValid) {
        this.sendRegistration();
      }

      this.submitted = true;

      return isValid;
    },
    async sendRegistration() {
      this.$store.commit("steps/stepCompleted", "Registration");

      this.sendButtonText = "Anmeldung wird gesendet...";
      this.sendButtonIcon = "pi pi-spin pi-spinner";
      this.buttonsDisabled = true;

      // fetch("https://mcmhhf-2m7hq4j1dydmv79z7qdb1.auth.marketingcloudapis.com/v2/token", {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   method: "POST",
      //   body: JSON.stringify({
      //     grant_type: "client_credentials",
      //     client_id: "z31bdcb5j21yyzq234tef957",
      //     client_secret: "dK5s2u17mnIc3KHvj05XOv72",
      //     // account_id: "510002901",
      //   }),
      // })
      //   .then(function (res) {
      //     console.log(res);
      //   })
      //   .catch(function (res) {
      //     console.log(res);
      //   });

      // preparing reqest data
      const planner = this.$store.state.planner;

      const key = uuidGenerator();

      let firstName = "";
      let lastName = "";

      if (planner.investorName !== "") {
        const investorName = planner.investorName.replace(/\s{2}/g, "").trim();
        if (investorName.indexOf(" ") === -1) {
          lastName = investorName;
        } else {
          firstName = investorName.substr(0, investorName.lastIndexOf(" "));
          lastName = investorName.substr(investorName.lastIndexOf(" ") + 1, investorName.length);
        }
      }

      const requestData: RequestData = {
        ContactKey: key,
        EventDefinitionKey: "APIEvent-54174f10-a75d-8cb1-82c6-1d33ddc39265",
        Data: {
          SubscriberKey: key,
          Email: planner.investorEmail,
          FirstName: firstName,
          LastName: lastName,
          InvestInterval: planner.investmentType,
          InvestSumPerInterval: planner.investmentTotal,
          RegistrationDate: dateFromISOString(),
          ETFPlanName: planner.investmentName,
          NoOfProductsSelected: this.selectedProducts.length,
          EmailInterval: planner.interval,
          TotalAmountInvested: planner.investmentTotal,
        },
      };

      this.selectedProducts.map((product: Product, index: number) => {
        const productNumber = index + 1;

        requestData.Data[`Prod${productNumber}ISIN`] = product.isin;
        requestData.Data[`Prod${productNumber}NAV`] = product.nav;
        requestData.Data[`Prod${productNumber}NAVDate`] = dateFromISOString(product.date);
        requestData.Data[`Prod${productNumber}AmountAllocation`] = product.amountAllocation;
        requestData.Data[`Prod${productNumber}PercentAllocation`] = product.percentAllocation;
      });

      console.log(JSON.stringify(requestData));
      console.log(requestData);

      // sending form data
      setTimeout(() => {
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Anmeldung gesendet",
        //   life: 10000,
        // });

        this.sendButtonText = "Anmeldung erfolgreich";
        this.sendButtonIcon = "pi pi-check";
        this.buttonsDisabled = false;

        // console.log("registration done", requestData);
      }, 2000);
    },
    scrollToFirstInvalidElement(retry: number) {
      retry++;

      const element = document.querySelector(".p-invalid");
      if (element) {
        const headerOffset = 37;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      } else if (retry < 10) {
        setTimeout(this.scrollToFirstInvalidElement, 100);
      }
    },
  },
});
