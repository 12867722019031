import { Commit } from "vuex";

import config from "@/configuration.json";
import { PropertyPayload } from "../index";

export interface ProductsPayload {
  products: Array<ProductCategory>;
}

export interface ToggleProductSelectedPayload {
  productId: string;
}

export interface SetProductPercentAllocationPayload {
  productId: string;
  percentAllocation: number;
  totalInvestment: number;
}

export interface ProductCategory {
  categoryId: string;
  categoryTitle: string;
  categoryImage: string;
  categoryProducts: Array<Product>;
}

export interface Product {
  productId: string;
  isin: string;
  productName: string;
  nav: number;
  date: string;
  selected?: boolean;
  amountAllocation?: number;
  percentAllocation?: number;
}

export interface WeightingRatios {
  [propName: number]: Array<number>;
}

export interface PlannerState {
  investmentType: string;
  investmentTotal: number;
  investmentName: string;
  products: Array<ProductCategory>;
  productsLoaded: boolean;
  minSelectedProducts: number;
  maxSelectedProducts: number;
  investorName: string;
  investorEmail: string;
  interval: string;
  termsAccepted: boolean;
  [propName: string]: string | number | boolean | Array<ProductCategory>;
}

export interface PlannerGetters {
  productsLoaded: boolean;
  productCategories: Array<ProductCategory>;
  selectedProducts: Array<Product>;
  selectedProductsCount: number;
  selectedProductsByCategoryCount: number;
  totalInvestment: number;
  totalAmountAllocation: number;
  totalPercentAllocation: number;
}

export type SelectedProductsByCategoryCountFunc = (categoryId: string) => number;

export default {
  namespaced: true,
  state: {
    investmentType: null,
    investmentTotal: null,
    investmentName: "",
    products: [],
    productsLoaded: false,
    minSelectedProducts: config.minSelectedProducts,
    maxSelectedProducts: config.maxSelectedProducts,
    investorName: "",
    investorEmail: "",
    interval: "monatlich",
    termsAccepted: false,
  },
  getters: {
    productsLoaded(state: PlannerState): boolean {
      return state.productsLoaded;
    },
    productCategories(state: PlannerState): Array<ProductCategory> {
      return state.products.slice(0);
    },
    minSelectedProducts(state: PlannerState): number {
      return state.minSelectedProducts;
    },
    maxSelectedProducts(state: PlannerState): number {
      return state.maxSelectedProducts;
    },
    selectedProducts(state: PlannerState): Array<Product> {
      const products: Array<Product> = [];

      state.products.map((category) => {
        const productsInCategory = category.categoryProducts.filter((product) => product.selected);
        products.push(...productsInCategory);
      });

      return products.slice(0);
    },
    selectedProductsCount(_: PlannerState, getters: PlannerGetters): number {
      return getters.selectedProducts.length;
    },
    selectedProductsByCategoryCount:
      (state: PlannerState): SelectedProductsByCategoryCountFunc =>
      (categoryId: string) => {
        let counter = 0;

        const category = state.products.find((category) => category.categoryId === categoryId);
        if (category) {
          counter = category.categoryProducts.filter((product) => product.selected).length;
        }

        return counter;
      },
    totalInvestment(state: PlannerState): number {
      return state.investmentTotal;
    },
    totalAmountAllocation(_: PlannerState, getters: PlannerGetters): number {
      let totalAmountAllocation = 0;

      getters.selectedProducts.map(
        (product) => (totalAmountAllocation += product.amountAllocation ? product.amountAllocation : 0)
      );

      return totalAmountAllocation;
    },
    totalPercentAllocation(_: PlannerState, getters: PlannerGetters): number {
      let totalPercentAllocation = 0;

      getters.selectedProducts.map(
        (product) => (totalPercentAllocation += product.percentAllocation ? product.percentAllocation : 0)
      );

      return totalPercentAllocation;
    },
  },
  mutations: {
    setValues(state: PlannerState, payload: PropertyPayload): void {
      state[payload.name] = payload.value;
    },
    setProducts(state: PlannerState, payload: ProductsPayload): void {
      state.products = payload.products;
      state.productsLoaded = true;
    },
    toggleProductSelected(state: PlannerState, payload: ToggleProductSelectedPayload): void {
      state.products.map((category) => {
        category.categoryProducts.map((product) => {
          if (product.productId === payload.productId) {
            product.selected = !product.selected;
            return;
          }
        });
      });
    },
    setProductAllocation(state: PlannerState, payload: SetProductPercentAllocationPayload): void {
      state.products.map((category) => {
        category.categoryProducts.map((product) => {
          if (product.productId === payload.productId) {
            product.percentAllocation = payload.percentAllocation;
            product.amountAllocation = payload.totalInvestment * (payload.percentAllocation / 100);
            return;
          }
        });
      });
    },
  },
  actions: {
    loadProducts: ({ commit }: { commit: Commit }): Promise<void> => {
      return new Promise((resolve, reject) => {
        fetch(config.productsUrl, { cache: "no-cache" })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            commit("setProducts", {
              products: data,
            });

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    initProductPercentAllocation: ({ commit, getters }: { commit: Commit; getters: PlannerGetters }): void => {
      const weightingRatios: WeightingRatios = config.weightingRatio;

      const selectedProducts = getters.selectedProducts;
      const weightingRatio = weightingRatios[selectedProducts.length] || [];

      if (weightingRatio.length === 0) {
        const weighting = Math.floor(100 / selectedProducts.length);
        let sum = 0;
        for (let i = 0; i < selectedProducts.length; i++) {
          sum += weighting;
          if (i < selectedProducts.length - 1) {
            weightingRatio.push(weighting);
          } else {
            weightingRatio.push(weighting + (100 - sum));
          }
        }
      }

      selectedProducts.map((product, index) => {
        commit("setProductAllocation", {
          productId: product.productId,
          percentAllocation: weightingRatio[index],
          totalInvestment: getters.totalInvestment,
        });
      });
    },
    setProductPercentAllocation: (
      { commit, getters }: { commit: Commit; getters: PlannerGetters },
      payload: SetProductPercentAllocationPayload
    ): void => {
      commit("setProductAllocation", {
        productId: payload.productId,
        percentAllocation: payload.percentAllocation,
        totalInvestment: getters.totalInvestment,
      });
    },
  },
};
