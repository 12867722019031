import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Investment from "@/components/EtfPlanner/1-Investment.vue";
import ProductSelection from "@/components/EtfPlanner/2-ProductSelection.vue";
import ProductWeighting from "@/components/EtfPlanner/3-ProductWeighting.vue";
import Registration from "@/components/EtfPlanner/4-Registration.vue";
import store from "@/store/index";
import { StepItem } from "@/store/modules/steps";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Investment",
    component: Investment,
  },
  {
    path: "/product-selection",
    name: "ProductSelection",
    component: ProductSelection,
    // component: () => import(/* webpackChunkName: "product" */ "@/components/EtfPlanner/2-ProductSelection.vue"),
  },
  {
    path: "/product-weighting",
    name: "ProductWeighting",
    component: ProductWeighting,
    // component: () => import(/* webpackChunkName: "product" */ "@/components/EtfPlanner/3-ProductWeighting.vue"),
  },
  {
    path: "/registration",
    name: "Registration",
    component: Registration,
    // component: () => import(/* webpackChunkName: "product" */ "@/components/EtfPlanner/4-Registration.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  let canNavigate = to.path == "/";
  const steps = store.getters["steps/availableSteps"] as Array<StepItem>;

  const stepIndex = steps.findIndex((x) => x.id == to.name);
  if (stepIndex) {
    canNavigate = canNavigate || store.getters["steps/isStepCompleted"](steps[stepIndex]);
  }

  if (stepIndex > 0 && store.getters["steps/isStepCompleted"](steps[stepIndex - 1])) {
    canNavigate = true;
  }

  if (!canNavigate) {
    return "/";
  }

  return true;
});

export default router;
