import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import steps from "./modules/steps";
import planner from "./modules/planner";

export interface PropertyPayload {
  name: string;
  value: string | number | boolean;
}

export default createStore({
  strict: process.env.NODE_ENV !== `production`,
  modules: {
    steps,
    planner,
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
