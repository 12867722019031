
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import Layout from "./_Layout.vue";
import Buttons from "./_Buttons.vue";

import { currencyFormatter, dateFormatter } from "@/helper/formatter";

export default defineComponent({
  name: "ProductSelection",
  components: { Layout, Buttons },
  data: function () {
    return {
      loading: true,
      error: null,
      invalid: false,
      submitted: false,
    };
  },
  computed: {
    ...mapGetters("planner", [
      "minSelectedProducts",
      "maxSelectedProducts",
      "productsLoaded",
      "productCategories",
      "selectedProductsCount",
      "selectedProductsByCategoryCount",
    ]),
  },
  mounted: function () {
    if (!this.productsLoaded) {
      this.$store
        .dispatch("planner/loadProducts")
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.error = error.message;
        });
    } else {
      this.loading = false;
    }
  },
  methods: {
    formatAsCurrency(value: number): string {
      return currencyFormatter.format(value);
    },
    formatAsDate(value: string): string {
      return dateFormatter.format(new Date(value));
    },
    toggleProductSelected(productId: string) {
      this.$store.commit("planner/toggleProductSelected", {
        productId,
      });

      this.validate(false);
    },
    validate(showTooltip = true) {
      const isValid =
        this.selectedProductsCount >= this.minSelectedProducts &&
        this.selectedProductsCount <= this.maxSelectedProducts;

      this.submitted = true;
      this.invalid = !isValid;

      if (isValid) {
        this.$store.dispatch("planner/initProductPercentAllocation");
      }
      if (!isValid && showTooltip) {
        this.$nextTick(() => {
          (this.$refs.tooltip as HTMLSpanElement).focus();
        });
      }

      return isValid;
    },
  },
});
