
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import Layout from "./_Layout.vue";
import Buttons from "./_Buttons.vue";

import { currencyFormatter, dateFormatter } from "@/helper/formatter";
import { Product } from "@/store/modules/planner";

export default defineComponent({
  name: "ProductWeighting",
  components: { Layout, Buttons },
  data: function () {
    return {
      submitted: false,
      invalid: false,
    };
  },
  computed: {
    ...mapGetters("planner", [
      "selectedProducts",
      "totalInvestment",
      "totalAmountAllocation",
      "totalPercentAllocation",
    ]),
  },
  methods: {
    formatAsCurrency(value: number): string {
      return currencyFormatter.format(value);
    },
    formatAsDate(value: string): string {
      return dateFormatter.format(new Date(value));
    },
    setProductPercentAllocation(product: Product, { value }: { value: number }): void {
      const percentAllocation = value > 100 ? 100 : value;

      this.$store.dispatch("planner/setProductPercentAllocation", {
        productId: product.productId,
        percentAllocation,
      });

      this.validate(false);
    },
    validate(showTooltip = true) {
      const isValid = this.totalPercentAllocation === 100;

      this.submitted = true;
      this.invalid = !isValid;

      if (!isValid && showTooltip) {
        this.$nextTick(() => {
          (this.$refs.tooltip as HTMLSpanElement).focus();
        });
      }

      return isValid;
    },
  },
});
