
import { defineComponent } from "vue";

export default defineComponent({
  name: "Buttons",
  props: {
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    hideLabelMobile: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    showError: {
      type: Boolean,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
});
