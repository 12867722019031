
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "Buttons",
  props: {
    stepName: {
      type: String,
      default: "",
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    showSkip: {
      type: Boolean,
      default: true,
    },
    showNext: {
      type: Boolean,
      default: true,
    },
    validateFunc: {
      type: Function,
      default: undefined,
    },
  },
  emits: ["skip"],
  computed: {
    ...mapGetters("steps", ["nextStep", "prevStep"]),
  },
  methods: {
    gotoPrev() {
      this.$router.push({ name: this.prevStep.id });
    },
    skip() {
      this.$emit("skip");
      this.$store.commit("steps/stepCompleted", this.stepName);
      this.$router.push({ name: this.nextStep.id });
    },
    async gotoNext() {
      if (!this.validateFunc || (await this.validateFunc()) === true) {
        this.$store.commit("steps/stepCompleted", this.stepName);
        this.$router.push({ name: this.nextStep.id });
      }
    },
  },
});
